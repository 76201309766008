import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import "./style.css";
import { review } from "../../../API/rating";

const ReviewModal = ({ open, setOpen, shopId, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [photoDescription, setPhotoDescription] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { Option } = Select;

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadChange = ({ fileList }) => {
    setUploadedPhotos(fileList);
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.shop_id = shopId;
    const apiData = new FormData();

    uploadedPhotos.forEach((file) => {
      // Check if the file is a valid File object, otherwise use a Blob if necessary
      if (file.originFileObj) {
        apiData.append("photos", file.originFileObj); // Append each file properly to FormData
      }
    });

    // Other form data
    apiData.append("ambiance", values.ambiance);
    apiData.append("clean", values.clean);
    apiData.append("hospitality", values.hospitality);
    apiData.append("remarks", values.remarks);
    apiData.append("service", values.service);
    apiData.append("shop_id", values.shop_id);
    apiData.append("treatment_duration", values.treatment_duration);
    apiData.append("treatment_spending", values.treatment_spending);
    apiData.append("value", values.value);
    apiData.append("date_of_visit", values.date_of_visit.format("YYYY-MM-DD"));

    console.log(values);
    // Send the data using an API request or your review function
    const res = await review(apiData);
    if (Math.floor(res?.status / 100) === 2) {
      setRefresh((prev) => !prev);
    } else {
      api["error"]({
        message: t("fetchingFailed"),
        description: res?.response?.data?.detail,
      });
    }

    setOpen(false);
    setLoading(false);
  };

  const onFinishFailed = (values) => {
    console.error(values);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        onCancel={handleClose}
        footer={false}
        className="review-modal"
      >
        <Form
          style={{ width: 350, marginTop: "40px" }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("dateOfVisit")}
            name="date_of_visit"
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                message: t("remarksWarning"),
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
          </Form.Item>

          <div className="double-items">
            <Form.Item
              label={t("treatmentDuration")}
              name="treatment_duration"
              style={{ marginBottom: "5px" }}
              className="double-item"
            >
              <Input suffix={t("hours")} />
            </Form.Item>
            <Form.Item
              label={t("treatmentSpending")}
              name="treatment_spending"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  message: t("pleaseEnterNumber"),
                  pattern: /^\d*\.?\d*$/,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="double-items">
            <Form.Item
              label={t("service")}
              name="service"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("cleanliness")}
              name="clean"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="double-items">
            <Form.Item
              label={t("hospitality")}
              name="hospitality"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("ambiance")}
              name="ambiance"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            label={t("value")}
            name="value"
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                message: t("selectWarning"),
              },
            ]}
          >
            <Select>
              {Array.from({ length: 10 }, (_, i) => (
                <Option key={i + 1} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("remarks")}
            name={"remarks"}
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                message: t("remarksWarning"),
              },
            ]}
          >
            <Input.TextArea
              placeholder={t("addDescriptionForRemarks")}
              value={photoDescription}
              onChange={(e) => setPhotoDescription(e.target.value)}
              style={{ marginTop: "10px" }}
            />
          </Form.Item>

          {/* Image Upload Section */}
          {/* <ImgCrop rotationSlider> */}
          <Upload
            listType="picture-card"
            multiple
            fileList={uploadedPhotos}
            onChange={handleUploadChange}
            beforeUpload={() => false}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>{t("upload")}</div>
            </div>
          </Upload>
          {/* </ImgCrop> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={loading}
              loading={loading}
            >
              {t("saveReview")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ReviewModal;
